import { brandConstants } from "@/brand/current/PartnerConstants";
import { InboxModel } from "@/models/InboxModel";

const aggregationTimeSpan = 3000; // 3 seconds

type AggregationTimeoutEvents = { [chatroomId: string]: NodeJS.Timeout | null };
type AggregatedEvents = { [chatroomId: string]: InboxModel[] };

const aggregatedEvents: AggregatedEvents = {};
const aggregationTimeoutEvents: AggregationTimeoutEvents = {};

export function showMessageNotification(chatroom: InboxModel) {
  const path = "/customer-service/inbox";
  const currentPath = window.location.pathname;
  const isPageVisible = document.visibilityState === "visible";

  if (isPageVisible && currentPath !== path) {
    console.log(
      "Page is visible but not on the target path, calling aggregateFunc."
    );
    aggregateFunc(chatroom);
  } else if (!isPageVisible) {
    console.log("Page is not visible (background), calling aggregateFunc.");
    aggregateFunc(chatroom);
  } else {
    console.log("Page is visible and on the target path, no action needed.");
  }
}

function aggregateFunc(chatroom: InboxModel): void {
  const chatroomId = chatroom.id;

  // Initialize or retrieve current events for the chatroom
  const chatroomEvents = aggregatedEvents[chatroomId] || [];

  // Add the new chatroom event
  chatroomEvents.push(chatroom);
  aggregatedEvents[chatroomId] = chatroomEvents;

  console.log(
    "Notification Improvement After: ",
    aggregationTimeoutEvents[chatroomId]
  );

  // Ensure timeout entry exists
  if (!(chatroomId in aggregationTimeoutEvents)) {
    aggregationTimeoutEvents[chatroomId] = null;
  }

  const timeout = aggregationTimeoutEvents[chatroomId];
  console.log("Notification Improvement: Timeout: ", timeout);

  if (!timeout) {
    // Set a timeout to aggregate messages
    aggregationTimeoutEvents[chatroomId] = setTimeout(() => {
      console.log("Notification Improvement: Enter Timeout");

      const numOfMessages = chatroomEvents.length;
      sendNotification(chatroom, numOfMessages);

      // Reset the aggregation for the chatroom
      aggregationTimeoutEvents[chatroomId] = null;
      aggregatedEvents[chatroomId] = [];
    }, aggregationTimeSpan);
  }
}

function sendNotification(chatroom: InboxModel, numOfMessages: number) {
  const customerName = chatroom.name
    ? chatroom.name
    : chatroom.customerPhoneNumber;
  let message = "";
  const url = `/customer-service/inbox?referenceId=${chatroom.id}`;
  const brandName = brandConstants.PARTNER_BRAND_NAME || "-";
  const mediaPlatform = chatroom?.mediaPlatform || "";
  let channelName = "";
  if (mediaPlatform === "WAP") {
    channelName = "Whatsapp";
  } else if (mediaPlatform === "IGM") {
    channelName = "Instagram";
  } else if (mediaPlatform === "MSG") {
    channelName = "Facebook";
  } else if (mediaPlatform === "TPD") {
    channelName = "Tokopedia";
  } else if (mediaPlatform === "LZD") {
    channelName = "Lazada";
  } else if (mediaPlatform === "SHP") {
    channelName = "Shopee";
  } else if (mediaPlatform === "TLG") {
    channelName = "Telegram";
  }
  if (channelName) {
    message = `[Inbox ${brandName}] You received ${numOfMessages} new messages in ${channelName} from ${customerName}`;
  } else {
    message = `[Inbox ${brandName}] You received ${numOfMessages} new messages from ${customerName}`;
  }
  console.log("Notification Improvement: Message: ", message);
  const notification = new Notification("Open Inbox", {
    body: message,
  });
  notification.onclick = (event) => {
    event.preventDefault();
    window.open(url);
  };
}
