import firebaseMessaging from "@/plugins/firebase";
/* eslint-disable no-console */

import { register } from "register-service-worker";
import store from "./store";
import { getUserDetailFromJwt } from "./utils/jwtUtil";
import { KokattoLoggingServiceClient } from "./serviceClients/KokattoLoggingServiceClient";

// if (process.env.NODE_ENV === "production") {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready(registration) {
    console.log(
      "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
    );
    store.dispatch("submitLog", {
      payload: { info: "App is being served from cache by a service worker." },
      type: "SERVICE_WORKER",
      isError: false,
    });
  },
  registered(registration) {
    console.log(
      "file: registerServiceWorker.ts:21 ~ registered ~ registration:",
      registration
    );
    console.log("Service worker has been registered.");
    console.log(
      "registered ~ JSON.parse(JSON.stringify(firebaseMessaging)):",
      JSON.parse(JSON.stringify(firebaseMessaging))
    );
    store.dispatch("submitLog", {
      payload: { info: "Service worker has been registered." },
      type: "SERVICE_WORKER",
      isError: false,
    });
  },
  cached() {
    console.log("Content has been cached for offline use.");
  },
  updatefound() {
    console.log("New content is downloading.");
  },
  updated() {
    // alert("updated");
    deleteCookies();
    deleteLocalStorage();
    console.log("New content is available; please refresh.");
  },
  offline() {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  },
  error(error) {
    console.error("Error during service worker registration:", error);
    store.dispatch("submitLog", {
      payload: { error, info: "Error during service worker registration:" },
      type: "SERVICE_WORKER",
      isError: true,
    });
  },
});
// }

function deleteCookies() {
  const Cookies = document.cookie.split(";");
  // set 1 Jan, 1970 expiry for every cookies
  for (let i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + "=;expires=" + new Date(0).toUTCString();
}

function deleteLocalStorage() {
  window.localStorage.clear();
}
