import axios, { AxiosRequestConfig } from "axios";
import { KokattoServiceClient } from "./KokattoServiceClient";
import { KokattoErrorResponse } from "./responses/KokattoErrorResponse";
import { FeaturesAccessModel } from "../models/FeaturesAccessModel";
import { UserModel } from "@/models/CentralizedIdentiyService/UserModel";
import {
  ValidateEmailOTPForgotPasswordRequest,
  ValidateEmailOTPForgotPasswordResponse,
} from "./requests/ForgotPasswordRequest";
import { KokattoMFAResponse } from "./responses/KokattoMFAResponse";

export class CentralizedIdentityServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_CIS_AWS ?? "";
    }
  }

  async getListUserFeaturesAccess(
    isDeleted?: boolean
  ): Promise<FeaturesAccessModel[] | KokattoErrorResponse> {
    const url = `${this.baseUrl}/feature-access/list`;
    return this.failsafeHttpRequest(async () => {
      const reqParam = {
        isDeleted,
      };

      const config: AxiosRequestConfig = {
        params: reqParam,
      };

      const response = await axios.get(url, config);
      return response.data;
    });
  }

  async getDetailUserFeaturesAccess(
    name: string
  ): Promise<FeaturesAccessModel | KokattoErrorResponse> {
    const url = `${this.baseUrl}/feature-access/detail`;
    return this.failsafeHttpRequest(async () => {
      const reqParam = {
        name,
      };

      const config: AxiosRequestConfig = {
        params: reqParam,
      };

      const response = await axios.get(url, config);
      return response.data;
    });
  }

  async createUserFeaturesAccess(
    requestBody: Partial<FeaturesAccessModel>
  ): Promise<FeaturesAccessModel | KokattoErrorResponse> {
    const url = `${this.baseUrl}/feature-access`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, requestBody);
      return response.data;
    });
  }

  async updateUserFeaturesAccess(
    name: string,
    requestBody: Partial<FeaturesAccessModel>
  ): Promise<FeaturesAccessModel | KokattoErrorResponse> {
    const url = `${this.baseUrl}/feature-access/${name}`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, requestBody);
      return response.data;
    });
  }

  async deleteUserFeaturesAccess(name: string): Promise<FeaturesAccessModel> {
    const url = `${this.baseUrl}/feature-access/${name}`;
    return this.failsafeHttpRequest(async () => {
      const config: AxiosRequestConfig = {};

      const response = await axios.delete(url, config);
      return response.data;
    });
  }

  async getOwnUserProfile(): Promise<UserModel> {
    const url = `${this.baseUrl}/users/me`;
    return this.failsafeHttpRequest(async () => {
      const config: AxiosRequestConfig = {};

      const response = await axios.get(url, config);
      return response.data;
    });
  }

  async validateEmailOTP(
    request: ValidateEmailOTPForgotPasswordRequest
  ): Promise<ValidateEmailOTPForgotPasswordResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/users/resetPassword/validate`;

    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request);
      return response.data;
    });
  }

  // API call to get QR Code
  async enableTwoFactorAuthentication(): Promise<
    KokattoMFAResponse | KokattoErrorResponse
  > {
    const url = `${this.baseUrl}/users/mfa/activate`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    });
  }

  // API for deactivating / verifiying 2FA
  async toggleTwoFactorAuthentication(
    action: string,
    token: string
  ): Promise<KokattoMFAResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/users/mfa/${action}`;
    return this.failsafeHttpRequest(async () => {
      const request = { token };
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });

      return response.data;
    });
  }

  async updateOwnUserConsent(consentTimestamp: number): Promise<UserModel> {
    const url = `${this.baseUrl}/users/consent`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, {
        consentTimestamp: consentTimestamp,
      });
      return response.data;
    });
  }

  async logout(token: string): Promise<string> {
    const url = `${this.baseUrl}/auth/logout`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, {
        token: token,
      });
      return response.data?.message;
    });
  }
}
