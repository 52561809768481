import { CustomerServiceModel } from "@/models/CsAgentManagementModel";
import { CallRoomSessionModel } from "@/models/InboundCall/CallRoomModel";
import { ActionTree, GetterTree, MutationTree } from "vuex";

const DEFAULT = {
  maxReconnectionDelay: 10000,
  minReconnectionDelay: 1000 + Math.random() * 4000,
  reconnectionDelayGrowFactor: 1.3,
  connectionTimeout: 4000,
  minUptime: 5000,
};
export class ErrorEvent extends Event {
  message: string;
  error: Error;
  constructor(error: Error, target: any) {
    super("error", target);
    this.message = error.message;
    this.error = error;
  }
}

const defaultState = (): State => {
  return {
    maxRetries: 10,
    retryCount: 0,
    connection: null,
    connectionURL: null,
    _connectTimeout: null,
    _uptimeTimeout: null,
    _options: {},
    _messageQueue: [],
    _shouldReconnect: true,
    _connectLock: false, //set initial to true to disable websocket temporarily
    customerService: null,
    activeCallRoom: null,
  };
};

type State = {
  maxRetries: number;
  retryCount: number;
  connection: WebSocket | null;
  connectionURL: string | null;
  _connectTimeout: NodeJS.Timeout | null;
  _uptimeTimeout: NodeJS.Timeout | null;
  _options: {
    reconnectionDelayGrowFactor?: number;
    minReconnectionDelay?: number;
    maxReconnectionDelay?: number;
    connectionTimeout?: number;
    minUptime?: number;
  };
  _messageQueue: Array<string | ArrayBufferLike | Blob | ArrayBufferView>;
  _shouldReconnect: boolean;
  _connectLock: boolean;
  customerService: CustomerServiceModel | null;
  activeCallRoom: CallRoomSessionModel | null;
};

const state: State = defaultState();

const getters: GetterTree<State, Record<string, never>> = {
  getIsMaxRetry: (state) => state.retryCount >= state.maxRetries,
  getConnection: (state) => state.connection,
  getRetryCount: (state) => state.retryCount,
  _getNextDelay: (state) => {
    const {
      reconnectionDelayGrowFactor = DEFAULT.reconnectionDelayGrowFactor,
      minReconnectionDelay = DEFAULT.minReconnectionDelay,
      maxReconnectionDelay = DEFAULT.maxReconnectionDelay,
    } = state._options;
    let delay = 0;
    if (state.retryCount > 0) {
      delay =
        minReconnectionDelay *
        Math.pow(reconnectionDelayGrowFactor, state.retryCount - 1);
      if (delay > maxReconnectionDelay) {
        delay = maxReconnectionDelay;
      }
    }
    return delay;
  },
  getConnectionState: (state) => {
    return state.connection && state.connection.readyState;
  },
  getIsConnectLock: (state) => state._connectLock,
  getIsShouldReconnect: (state) => state._shouldReconnect,
  getCustomerService: (state) => state.customerService,
  getActiveCallRoom: (state) => state.activeCallRoom,
};

const mutations: MutationTree<State> = {
  resetWSState(state) {
    state.connection?.close();
    Object.assign(state, defaultState());
    state._connectTimeout && clearTimeout(state._connectTimeout);
    state._uptimeTimeout && clearTimeout(state._uptimeTimeout);
  },
  updateMaxRetry(state, condition: number) {
    state.maxRetries = condition;
  },
  updateRetryCount(state, count: number) {
    state.retryCount = count;
  },
  increaseRetryCount(state) {
    state.retryCount++;
  },
  setConnection(state, ws: WebSocket | null) {
    state.connection = ws;
  },
  clearTimeout(state, timeout: NodeJS.Timeout) {
    clearTimeout(timeout);
  },
  clearTimeouts(state) {
    state._connectTimeout && clearTimeout(state._connectTimeout);
    state._uptimeTimeout && clearTimeout(state._uptimeTimeout);
  },
  setConnectTimeout(state, timeout: NodeJS.Timeout) {
    state._connectTimeout = timeout;
  },
  setUptimeTimeout(state, minUptime) {
    state._uptimeTimeout = setTimeout(() => {
      // console.log(`${webSocket.name} onOpen Event - accept open`);
      // console.log(
      //   "file: InboundCall/index.ts:107 ~ setUptimeTimeout ~ minUptime:",
      //   minUptime
      // );
      state.retryCount = 0;
    }, minUptime);
  },
  clearMessageQueue(state) {
    state._messageQueue = [];
  },
  updateShouldReconnect(state, bool: boolean) {
    state._shouldReconnect = bool;
  },
  updateConnectLock(state, bool: boolean) {
    state._connectLock = bool;
  },
  setCustomerService(state, cs: CustomerServiceModel) {
    state.customerService = cs;
  },
  setActiveCallRoom(state, callRoom: CallRoomSessionModel) {
    state.activeCallRoom = callRoom;
  },
};

const actions: ActionTree<State, Record<string, never>> = {
  async clearWSState(context) {
    context.commit("resetWSState");
    await context.dispatch("_removeListeners");
  },
  async resetRetryAndLock(context) {
    context.commit("updateConnectLock", false);
    context.commit("updateRetryCount", 0);
  },
  setSetIsMaxRetry(context, condition: boolean) {
    context.commit("updateMaxRetry", condition);
  },
  onMessage(context, message: Record<string, unknown>) {
    // console.log("file: Websocket.ts:37 ~ onMessage ~ message:", message);
    // context.commit("updateMaxRetry", condition);
  },
  onOpen(context, ev: Event) {
    // console.log("file: InboundCall/index.ts:140 ~ onOpen ~ ev:", ev);
    const { state } = context;
    const { minUptime = DEFAULT.minUptime } = state._options;

    context.commit("clearTimeout", state._connectTimeout);
    context.commit("setUptimeTimeout", minUptime);

    state._messageQueue.forEach((message) => {
      return state.connection?.send(message);
    });
    context.commit("clearMessageQueue");
  },
  _removeListeners(context) {
    const { state } = context;
    if (!state.connection || !state.connection.removeEventListener) return;
    // console.log(
    //   "file: InboundCall/index.ts:154 ~ _removeListeners ~ state.connection:",
    //   state.connection
    // );
    // console.log(`${webSocket.name} removeListeners`);
    state.connection.removeEventListener(
      "open",
      async (ev: Event) => await context.dispatch("onOpen", ev)
    );
    state.connection.removeEventListener(
      "close",
      async (ev: Event) => await context.dispatch("onClose", ev)
    );
    state.connection.removeEventListener(
      "message",
      async (ev: Event) => await context.dispatch("onMessage", ev)
    );
    state.connection.removeEventListener(
      "error",
      async (ev: Event) => await context.dispatch("onError", ev)
    );
  },
  _addListeners(context) {
    const { state } = context;
    if (!state.connection || !state.connection.addEventListener) return;
    // console.log(
    //   "file: InboundCall/index.ts:175 ~ _addListeners ~ state.connection:",
    //   state.connection
    // );
    // console.log(`${webSocket.name} removeListeners`);
    state.connection.addEventListener(
      "open",
      async (ev: Event) => await context.dispatch("onOpen", ev)
    );
    state.connection.addEventListener(
      "close",
      async (ev: Event) => await context.dispatch("onClose", ev)
    );
    state.connection.addEventListener("message", async (ev: MessageEvent) => {
      // filters out notif from other clientId
      try {
        const stringEventData = ev.data;
        const parsedEventData = JSON.parse(stringEventData);
        const notifData = parsedEventData.data;
        if (
          notifData &&
          (notifData.chat || notifData.chatroom || notifData.cs)
        ) {
          const stringData =
            notifData.chat || notifData.chatroom || notifData.cs;
          const _data = JSON.parse(stringData);
          const clientId = _data.clientId;
          const currentClientId = context.rootGetters.getClientId;
          if (clientId !== currentClientId) return;
          // console.log("file: InboundCall/index.ts:113 ~ clientId:", clientId);
        }
      } catch (error) {
        console.log(
          "file: InboundCall/index.ts:110 ~ error on filtering out other client notif:",
          error
        );
      }
      await context.dispatch("onMessage", ev);
    });
    state.connection.addEventListener(
      "error",
      async (ev: Event) => await context.dispatch("onError", ev)
    );
  },
  async _disconnect(context, payload) {
    context.commit("clearTimeouts");
    const { state } = context;
    if (!state.connection || !state.connection.close) return;
    await context.dispatch("_removeListeners");
    try {
      context.commit("updateShouldReconnect", false);
      context.commit("updateConnectLock", false);
      // console.log(
      //   "file: InboundCall/index.ts:215 ~ _disconnect ~ state._connectLock:",
      //   state._connectLock
      // );
      context.commit("updateRetryCount", -1);
      if (state.connection.readyState === WebSocket.OPEN) {
        state.connection.close(payload.code, payload.reason);
      }
      // if (payload.reason !== "logout") {
      context.commit("updateShouldReconnect", true);
      // }
    } catch (error) {
      // console.log("file: InboundCall/index.ts:282 ~ _disconnect ~ error:", error);
    }
  },
  _wait(context) {
    return new Promise((resolve) => {
      setTimeout(resolve, context.getters._getNextDelay);
    });
  },
  async _handleTimeout(context) {
    // console.log("file: InboundCall/index.ts:226 ~ _handleTimeout");
    // console.log(`${webSocket.name} handle timeout`);
    await context.dispatch("onError", new ErrorEvent(Error("TIMEOUT"), this));
  },
  async onError(context, event) {
    console.log("file: InboundCall/index.ts:236 ~ onError ~ event:", event);
    await context.dispatch("_disconnect", {
      code: undefined,
      reason: event.message === "TIMEOUT" ? "timeout" : undefined,
    });
    // await context.dispatch("openWSConnection");
  },
  async onClose(context, ev: Event) {
    console.log("file: InboundCall/index.ts:244 ~ onClose ~ ev:", ev);
    context.commit("clearTimeouts");
    if (
      context.getters.getIsShouldReconnect &&
      context.rootGetters.getKokattoTokenAccess
    ) {
      setTimeout(function () {
        context.dispatch("openWSConnection");
      }, 1000);
    }
  },
  async openWSConnection(context, auth: string) {
    const { state, getters, rootGetters } = context;
    // console.log(
    //   "file: InboundCall/index.ts:238 ~ openWSConnection ~ getters.getIsConnectLock || !getters.getIsShouldReconnect:",
    //   getters.getIsConnectLock,
    //   !getters.getIsShouldReconnect
    // );
    if (
      (getters.getIsConnectLock || !getters.getIsShouldReconnect) &&
      !getters.getConnection
    ) {
      return;
    }
    context.commit("updateConnectLock", true);
    const { connectionTimeout = DEFAULT.connectionTimeout } = state._options;
    // console.log(
    //   "file: InboundCall/index.ts:250 ~ openWSConnection ~ getters.getIsMaxRetry:",
    //   getters.getIsMaxRetry
    // );
    if (getters.getIsMaxRetry) {
      return;
    }
    context.commit("increaseRetryCount");
    const removeListenerRes = await context.dispatch("_removeListeners");

    context.dispatch("_wait").then(async () => {
      if (!auth && rootGetters.getKokattoTokenAccess) {
        auth = rootGetters.getKokattoTokenAccess;
      } else if (!rootGetters.getKokattoTokenAccess) {
        return;
      }
      const websocketUrl =
        process.env.VUE_APP_BASEURL_WEBSOCKET_INBOUND_CALL_URL ||
        "wss://4eqb8yyj05.execute-api.ap-southeast-1.amazonaws.com/dev?authorization=Bearer%20";
      // console.log(
      //   "file: InboundCall/index.ts:291 ~ context.dispatch ~ websocketUrl + auth:",
      //   websocketUrl + auth
      // );
      context.commit("setConnection", new WebSocket(websocketUrl + auth));
      context.commit("updateConnectLock", false);
      // console.log(
      //   "file: InboundCall/index.ts:269 ~ context.dispatch ~ state._connectLock:",
      //   state._connectLock
      // );
      await context.dispatch("_addListeners");

      context.commit(
        "setConnectTimeout",
        setTimeout(
          async () => await context.dispatch("_handleTimeout"),
          connectionTimeout
        )
      );
      // console.log(
      //   "file: InboundCall/index.ts:282 ~ context.dispatch ~ state._connectTimeout:",
      //   state._connectTimeout
      // );
    });
  },
  async reconnectWS(context) {
    const { state, getters, dispatch } = context;
    context.commit("clearTimeout", state._connectTimeout);
    context.commit("clearTimeout", state._uptimeTimeout);
    if (getters.getIsShouldReconnect) {
      await dispatch("openWSConnection");
    }
  },
  setCustomerService(context, customerService: CustomerServiceModel) {
    context.commit("setCustomerService", customerService);
  },
  setActiveCallRoom(context, callRoom: CallRoomSessionModel) {
    context.commit("setActiveCallRoom", callRoom);
  },
};

const Websocket = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default Websocket;
