import { KokattoServiceClient } from "./KokattoServiceClient";
import { KokattoErrorResponse } from "./responses/KokattoErrorResponse";
import axios from "axios";
import { KokattoGetCustomerServiceResponse } from "./responses/KokattoGetCustomerServiceResponse";
import { KokattoUpdateAutoReplyRequest } from "./requests/KokattoUpdateAutoReplyRequest";
import { KokattoGetAutoReplyResponse } from "./responses/KokattoGetAutoReplyResponse";
import { KokattoConfigurationResponse } from "./responses/KokattoConfigurationResponse";
import { KokattoConfigurationRequest } from "./requests/KokattoConfigurationRequest";
import {
  KokattoGetCategoryListResponse,
  KokattoCreateCategoryResponse,
} from "./responses/KokattoGetCategoryListResponse";
import { KokattoCreateCategoryRequest } from "./requests/KokattoCreateCategoryRequest";
import { KokattoEditCategoryRequest } from "./requests/KokattoEditCategoryRequest";
import { KokattoDeleteCategoryRequest } from "./requests/KokattoDeleteCategoryRequest";
import { KokattoGetQuickRepliesResponse } from "./responses/KokattoGetQuickRepliesResponse";
import { KokattoGetTagListResponse } from "./responses/KokattoGetTagListResponse";
import { KokattoGetTagRequest } from "./requests/KokattoTagRequest";
import { KokattoGetCSPlatformConfigResponse } from "@/serviceClients/responses/KokattoGetCSPlatformConfigResponse";

export class KokattoConfigServiceClient extends KokattoServiceClient {
  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    super(args);
    if (!this.baseUrl) {
      this.baseUrl = process.env.VUE_APP_BASEURL_KOKATTO_PORTAL ?? "";
    }
  }

  async getAutoReply(): Promise<
    KokattoGetAutoReplyResponse | KokattoErrorResponse
  > {
    const url = `${this.baseUrl}/csplatform/autoReply`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async updateAutoReply(
    request: KokattoUpdateAutoReplyRequest
  ): Promise<KokattoGetAutoReplyResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/autoReply`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getChatTemplate(): Promise<
    KokattoGetQuickRepliesResponse | KokattoErrorResponse
  > {
    const url = `${this.baseUrl}/csplatform/chatTemplate`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getConfig(): Promise<
    KokattoGetCSPlatformConfigResponse | KokattoErrorResponse
  > {
    const url = `${this.baseUrl}/csplatform/configuration`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async updateConfig(
    request: KokattoConfigurationRequest
  ): Promise<KokattoConfigurationResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/configuration`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async getAllChatroomCategories(): Promise<
    KokattoGetCategoryListResponse | KokattoErrorResponse
  > {
    const url = `${this.baseUrl}/csplatform/categories`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async createChatroomCategory(
    request: KokattoCreateCategoryRequest
  ): Promise<KokattoCreateCategoryResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/categories`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.post(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async updateChatroomCategory(
    request: KokattoEditCategoryRequest
  ): Promise<KokattoCreateCategoryResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/categories`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.put(url, request, {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }

  async deleteChatroomCategory(
    request: KokattoEditCategoryRequest
  ): Promise<KokattoCreateCategoryResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/categories`;
    const req = {
      data: request,
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    };
    return this.failsafeHttpRequest(async () => {
      const response = await axios.delete(url, req);
      return response.data;
    });
  }

  async getAllTicketTags(
    request?: KokattoGetTagRequest
  ): Promise<KokattoGetTagListResponse | KokattoErrorResponse> {
    const url = `${this.baseUrl}/csplatform/tags`;
    return this.failsafeHttpRequest(async () => {
      const response = await axios.get(url, {
        params: request,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    });
  }
}
