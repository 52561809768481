import "./router/componentHooks";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuetifyMoney from "./plugins/vuetify-money";
import { abilitiesPlugin, Can } from "@casl/vue";
import { Ability } from "@casl/ability";
import * as VueGoogleMaps from "vue2-google-maps";
import * as braze from "@braze/web-sdk";
import applyAppTokenRefreshInterceptor from "@/interceptor/axiosInterceptor";
// import VueFbCustomerChat from "vue-fb-customer-chat";
import axios from "axios";
import { brandConstants } from "./brand/current/PartnerConstants";
// import { WebSocketPlugin } from "./plugins/web-socket";
import InfiniteLoading from "vue-infinite-loading";
import firebaseMessaging from "@/plugins/firebase";
// import VueShepherdPlugin from "vue-shepherd";
// import "~shepherd.js/dist/css/shepherd.css";
import VueTut from "vue-tut";
import { KokattoLoggingServiceClient } from "./serviceClients/KokattoLoggingServiceClient";
import { getUserDetailFromJwt } from "@/utils/jwtUtil";

const ability = new Ability();
applyAppTokenRefreshInterceptor(axios);

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
});
Vue.component("Can", Can);
Vue.use(VuetifyMoney);
Vue.component("VuetifyMoney", VuetifyMoney);
Vue.use(InfiniteLoading);
Vue.use(VueTut);
// if (brandConstants.IS_FBM_ACTIVE) {
//   Vue.use(VueFbCustomerChat, {
//     page_id: "102427855593366", //  change 'null' to your Facebook Page ID,
//     theme_color: "#1843a1", // theme color in HEX,
//     appId: process.env.VUE_APP_FB_APP_ID, // Facebook App ID
//     greeting_dialog_display: "hide",
//   });
// }

declare const window: any;
if (typeof window !== "undefined") {
  console.log("window: " + window);
  if (typeof window.FB !== "undefined") {
    console.log("window.FB: " + window.FB);
    window.FB.CustomerChat.hide();
  }
}

const BRAZE_TOKEN =
  process.env.VUE_APP_BRAZE_API_TOKEN || "8455c9c7-3b0b-4b6f-9fd0-068038e06384";
const BRAZE_URL = process.env.VUE_APP_BRAZE_API_URL || "sdk.iad-06.braze.com";

braze.initialize(BRAZE_TOKEN, {
  baseUrl: BRAZE_URL,
  manageServiceWorkerExternally: true,
  enableLogging: true,
  allowUserSuppliedJavascript: true,
});

if (brandConstants.IS_BRAZE_ACTIVE) {
  braze.requestPushPermission();
  braze.automaticallyShowInAppMessages();
  braze.openSession();
}

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_ACCESS_KEY || "",
    libraries: "places",
  },
  installComponents: true,
});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    navigator.serviceWorker.addEventListener("message", (event) => {
      console.log("Received message from service worker:", event.data);
      // Send to kokatto logs
      const data = event.data;
      const { trackerRequest } = data;
      if (trackerRequest) {
        const token = store.getters.getKokattoTokenAccess;

        const serviceClient = new KokattoLoggingServiceClient({
          token: token,
        });

        let source = "BROWSER";
        if (window.matchMedia("(display-mode: standalone)").matches) {
          source = "APP";
        }

        const trackerData = {
          message: trackerRequest.chat
            ? trackerRequest.chat.content
            : undefined,
          messageId: trackerRequest.chat
            ? trackerRequest.chat.messageId
            : undefined,
          phoneNumber: trackerRequest.chat
            ? trackerRequest.chat.customerPhoneNumber
            : undefined,
          createdAt: new Date(trackerRequest.receiveTimestamp).toISOString(),
          source: source,
        };

        const request = {
          event: JSON.stringify(trackerData),
          result: "success", // success | failed
          type: trackerRequest.type || "SERVICE_WORKER",
        };

        serviceClient.postLog(request);
      }
    });
  });
}

Vue.config.productionTip = false;
// Vue.prototype.$ws = new WebSocketPlugin();
Vue.prototype.$messaging = firebaseMessaging;

new Vue({
  i18n: vuetify.i18n,
  router,
  store,
  vuetify: vuetify.vuetify,
  render: (h) => h(App),
}).$mount("#app");
