import { AxiosError } from "axios";
import { KokattoErrorResponse } from "./responses/KokattoErrorResponse";

export abstract class KokattoServiceClient {
  protected baseUrl: string;
  protected cisUrl: string;
  protected token: string;
  protected pushNotifUrl: string;

  constructor(
    args?:
      | string
      | {
          baseUrl?: string;
          token?: string;
        }
  ) {
    if (typeof args === "string") {
      this.baseUrl = args;
      this.token = process.env.VUE_APP_KOKATTO_TOKEN ?? "";
    } else {
      this.baseUrl = args?.baseUrl ?? "";
      this.token = args?.token ?? process.env.VUE_APP_KOKATTO_TOKEN ?? "";
    }
    this.cisUrl = process.env.VUE_APP_BASEURL_CIS_AWS ?? "";
    this.pushNotifUrl =
      `${process.env.VUE_APP_BASEURL_KOKATTO_PORTAL}/csppushnotif` ?? "";
  }

  protected async failsafeHttpRequest<T>(
    requestFunction: () => Promise<T | KokattoErrorResponse>
  ): Promise<T | KokattoErrorResponse> {
    try {
      return await requestFunction();
    } catch (error: any) {
      const axiosError = error as AxiosError<KokattoErrorResponse>;
      return (
        axiosError.response?.data ?? {
          statusCode: error?.response?.status || 500,
          error: error?.response?.data?.error || "error",
          message: error?.response?.data?.message || "Fail to send request",
        }
      );
    }
  }

  protected async failsafeHttpRequestThrowable<T>(
    requestFunction: () => Promise<T>
  ): Promise<T> {
    try {
      return await requestFunction();
    } catch (error: unknown) {
      if (!(error instanceof AxiosError)) {
        throw error;
      }

      const axiosError = error as AxiosError<KokattoErrorResponse>;
      throw axiosError;
    }
  }
}
