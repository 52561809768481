const id = {
  menuInboundCall: "Whatsapp Incoming Call",
  menuInboundCallHistory: "Riwayat Whatsapp Incoming Call",
  menuInboundCallHistorySubtitle:
    "Kamu bisa melihat riwayat call yang sudah dilakukan",
  callAssigned: "Telepon Ditugaskan",
  callQueue: "Telepon Antri",
  searchContactLabel: "Cari kontak atau keyword",
  callDuration: "Durasi Telepon",
  callNotes: "Catatan:",
  voiceCall: "Panggilan Suara",
  callFollowupDuration: "Panggilan ditutup dengan durasi ",
  callFollowup: "Kirim pesan follow up",
  callConnected: "Tersambung",
  callIncomingText: "Panggilan masuk dari ",
  callIncomingMissed: "Panggilan tidak terjawab ",
  callIncomingAssign: "Ditugaskan ke cs",
  callVoicebotText: "Ditangani Voice Bot ",
  callQueueText: " antri disambungkan ke agen",
  callAssignedText: " ditugaskan ke cs ",
  inboundCallIncomingCallLabel: "Panggilan Masuk",
  inboundCallAnswerCallNotification: "Jawab",
  inboundCallRejectCallNotification: "Tolak",
  inboundCallANSStatus: "Sedang Dalam Panggilan",
  inboundCallRINStatus: "Panggilan Masuk",
  inboundCallCMPStatus: "Panggilan Selesai",
  inboundCallRJCStatus: "Panggilan Ditolak",
  inboundCallNOAStatus: "Panggilan Tidak Terjawab",
  inboundCallErrorMessage: "Terjadi kesalahan, coba lagi",
  noOpenChatTitle: "Chat kosong",
  noOpenChatSubtitle: "Tidak ada chat yang di buka",
  downloadCallHistory: "Unduh Riwayat Panggilan",
  tabCallHistory: "Riwayat panggilan",
  tabCallExportHistory: "Permintaan export riwayat",
  labelSearchContact: "Cari Nomor Telepon",
  labelSearchUser: "Pilih User",
  labelSearchDate: "Pilih Waktu Panggilan",
  mediaTypeCallWAP: "Whatsapp Call",
  headerNameAndPhone: "Nama & Nomor Kontak",
  headerTicketId: "Id Tiket",
  headerCSAgent: "Agen CS",
  headerChannel: "Channel",
  headerResolveTime: "Waktu Resolusi",
  headerClosedBy: "Ditutup Oleh",
  headerAction: "Aksi",
  historyRequestType: "Tipe Riwayat",
  historyCallType: "Riwayat panggilan",
  recordCallType: "Rekaman panggilan",
  validationDateRequiredMessage: "Tanggal harus diisi",
  requestHistoryStatusDone: "Selesai",
  requestHistoryStatusInProgress: "Sedang diproses",
  requestHistoryStatusFailed: "Gagal",
  tooltipDownloadReport: "Unduh riwayat",
  tooltipCannotDownloadReport: "Permintaan riwayat sedang diproses",
  headerReportFilename: "Nama File",
  headerReportUser: "User",
  headerReportType: "Tipe Riwayat",
  headerReportCreatedAt: "Tanggal Dibuat",
  headerReportStatus: "Status",
  errorOpeningRecord: "Terjadi kesalahan ketika membuka rekaman",
};

const en = {
  menuInboundCall: "Whatsapp Incoming Call",
  menuInboundCallHistory: "Whatsapp Incoming Call History",
  menuInboundCallHistorySubtitle:
    "You can view the history of calls that have been made.",
  callAssigned: "Call Assigned",
  callQueue: "Call Queue",
  searchContactLabel: "Search Contact and Keyword",
  callDuration: "Call Duration",
  callNotes: "Notes:",
  voiceCall: "Voice Call",
  callFollowupDuration: "Call Ended duration ",
  callFollowup: "Send follow up message",
  callConnected: "Connected",
  callIncomingText: "Incoming Call from ",
  callIncomingMissed: "Missed call ",
  callIncomingAssign: "assigned to cs",
  callVoicebotText: "Voice Bot handle ",
  callQueueText: " queue connect to agent",
  callAssignedText: " assigned to cs ",
  inboundCallIncomingCallLabel: "Incoming Call",
  inboundCallAnswerCallNotification: "Answer",
  inboundCallRejectCallNotification: "Reject",
  inboundCallANSStatus: "On a call",
  inboundCallRINStatus: "Ringing",
  inboundCallCMPStatus: "Call ended",
  inboundCallRJCStatus: "Call rejected",
  inboundCallNOAStatus: "Missed call",
  inboundCallErrorMessage: "An error occurred, try again",
  noOpenChatTitle: "Empty chat",
  noOpenChatSubtitle: "There is no open chat",
  downloadCallHistory: "Download Call History",
  tabCallHistory: "Call history",
  tabCallExportHistory: "Request export history",
  labelSearchContact: "Search Phone Number",
  labelSearchUser: "Search User",
  labelSearchDate: "Choose Call Date",
  mediaTypeCallWAP: "Whatsapp Call",
  headerNameAndPhone: "Name & Contact Number",
  headerTicketId: "Ticket Id",
  headerCSAgent: "CS Agent,",
  headerChannel: "Channel",
  headerResolveTime: "Resolve Time",
  headerClosedBy: "Closed By",
  headerAction: "Action",
  historyRequestType: "History Type",
  historyCallType: "Call history",
  recordCallType: "Call record",
  validationDateRequiredMessage: "Date required",
  requestHistoryStatusDone: "Done",
  requestHistoryStatusInProgress: "In progress",
  requestHistoryStatusFailed: "Failed",
  tooltipDownloadReport: "Download history",
  tooltipCannotDownloadReport: "Export report currently being processed",
  headerReportFilename: "File Name",
  headerReportUser: "User",
  headerReportType: "History Type",
  headerReportCreatedAt: "Create Date",
  headerReportStatus: "Status",
  errorOpeningRecord: "Something wrong opening record data",
};

export default { id, en };
