const id = {
  pushNotificationBanner:
    "Gagal mengaktifkan notifikasi. Klik 'Aktifkan' untuk mulai menerima pesan chat secara otomatis.",
  pushNotificationBannerCTA: "Aktifkan",
};

const en = {
  pushNotificationBanner:
    "Failed to activate notifications. Click 'Activate' to start receiving chat messages automatically.",
  pushNotificationBannerCTA: "Activate",
};
export default { id, en };
