// Intercept and refresh expired tokens for multiple requests (same implementation but with some abstractions)
//
// HOW TO USE:
// import applyAppTokenRefreshInterceptor from 'axios.refresh_token.2.js';
// import axios from 'axios';
// ...
// applyAppTokenRefreshInterceptor(axios); // register the interceptor with all axios instance
// ...
// - Alternatively:
// const apiClient = axios.create({baseUrl: 'example.com/api'});
// applyAppTokenRefreshInterceptor(apiClient); // register the interceptor with one specific axios instance
// ...
// - With custom options:
// applyAppTokenRefreshInterceptor(apiClient, {
//      shouldIntercept: (error) => {
//          return error.response.data.errorCode === 'EXPIRED_ACCESS_TOKEN';
//      }
// ); // register the interceptor with one specific axios instance
//
// PS: You may need to figure out some minor things yourself as this is just a proof of concept and not a tutorial.
// Forgive me in advance

import axios, { AxiosRequestConfig } from "axios";
import store from "@/store";
import { KoalaRefreshAccessRequest } from "@/serviceClients/requests/KoalaRefreshAccessRequest";
import * as CryptoJS from "crypto-js";
import { getKoalaUserDetail } from "@/utils/jwtUtil";
import router from "@/router";
import { brandConstants } from "@/brand/current/PartnerConstants";
import { KoalaPlusAccountActivityRequest } from "@/serviceClients/requests/KoalaPlusAccountActivityRequest";
import { KoalaAccountServiceClient } from "@/serviceClients/KoalaAccountServiceClient";
import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KoalaPlusAccountActivityServiceClient } from "@/serviceClients/KoalaPlusAccountActivityServiceClient";

const IdentityBaseUrl = process.env.VUE_APP_BASEURL_KOALA_IDENTITY;
const KokattoIdentityBaseUrl = process.env.VUE_APP_BASEURL_KOKATTO_PORTAL;
const excludedAuthUrl = [
  process.env.VUE_APP_BASEURL_KOALA_IDENTITY + "/auth/koala-plus/register",
  process.env.VUE_APP_BASEURL_KOALA_PLUS + "/account/create/partner",
  process.env.VUE_APP_BASEURL_KOALA_PLUS + "/account/login",
];
const kokattoDomain = [
  process.env.VUE_APP_BASEURL_CIS,
  process.env.VUE_APP_BASEURL_CIS_AWS,
  process.env.VUE_APP_METABASE_URL,
  process.env.VUE_APP_BASEURL_KOKATTO_PORTAL,
  process.env.VUE_APP_BASEURL_KOKATTO_PUSHNOTIF_TRACKER,
  process.env.VUE_APP_BASEURL_TICKET_SERVICE,
] as string[];
const koalaDomain = [
  process.env.VUE_APP_BASEURL_KOALA_PLUS,
  process.env.VUE_APP_BASEURL_KOALA_OLTP,
  process.env.VUE_APP_BASEURL_KOALA_IDENTITY,
  process.env.VUE_APP_BASEURL_SHOPIFY,
] as string[];
const procatDomain = [
  process.env.VUE_APP_BASEURL_PRODUCT_CATALOG,
  process.env.VUE_APP_BASEURL_PRODUCT_CATALOG_STORE,
  process.env.VUE_APP_BASEURL_PRODUCT_CATALOG_WEB,
] as string[];

const shouldIntercept = (error: any) => {
  console.log("file: interceptor ~ line 21 ~ shouldIntercept");
  const request = error.config;
  try {
    // if (!store.getters.getIsLoggedIn()) {
    //   //console.log("file: interceptor ~ line 21 ~ USER NOT LOGIN");
    //   const parser = document.createElement("a");
    //   parser.href = request.url;
    //   const path = parser.pathname.split("/");
    //   //console.log(path);
    //   if (path[path.length - 1] !== "login") {
    //     // expireSession();
    //     return false;
    //   }
    // }

    console.log("file: interceptor ~ line 21 ~ shouldIntercept", error);
    return error.response && error.response.status === 401;
  } catch (e) {
    console.log("file: interceptor ~ line 53 ~ shouldIntercept", e);
    return false;
  }
};

const setTokenData = async (tokenData = {}, axiosClient: any) => {
  //console.log("file: interceptor.js setTokenData ~ tokenData", tokenData);
  // If necessary: save to storage
  //   tokenData's content includes data from handleTokenRefresh(): {
  //     idToken: data.auth_token,
  //     refreshToken: data.refresh_token,
  //     expiresAt: data.expires_in,
  // };
  await store.dispatch("storeTokenKokatto", tokenData);
  //console.log("file: interceptor.js storeTokenKokatto");
  await store.dispatch("storeTokenKoala", tokenData);
  //console.log("file: interceptor.js storeTokenKoala");
  // await store.dispatch("storeTokenProductCatalog", tokenData);
};

const handleTokenRefresh = () => {
  //console.log("file: interceptor ~ line 21 ~ handleTokenRefresh");

  const refreshRequest: KoalaRefreshAccessRequest = {
    koalaRefreshToken: store.getters.getKoalaTokenRefresh,
    kokattoAccessToken: store.getters.getKokattoTokenAccess,
    kokattoRefreshToken: store.getters.getKokattoTokenRefresh,
    //procatRefreshToken: store.getters.getProductCatalogTokenRefresh,
  };

  return new Promise((resolve, reject) => {
    const url = `${IdentityBaseUrl}/auth/koala-plus/refresh-access`;
    const refreshInstance = axios.create(); //separate instance
    refreshInstance
      .post(url, refreshRequest, {
        headers: {
          authorization: `Bearer ${store.getters.getKoalaTokenAccess}`,
        },
      })
      .then(({ data }) => {
        console.log("SUCCESS ~ handleTokenRefresh");
        const tokenData = {
          ...data,
        };
        resolve(tokenData);
      })
      .catch((err) => {
        console.log("ERROR ~ handleTokenRefresh", err);
        reject(err);
      });
  });
};

// const handleRequestLogout = () => {
//   const clientId = store.getters.getClientId;
//   const email =
//     brandConstants.PARTNER_PREFIX_EMAIL + store.getters.getLoginEmail;
//   const preSharedKey = `${clientId}|${email}`;

//   const hash = CryptoJS.SHA256(preSharedKey);
//   const iv = CryptoJS.lib.WordArray.random(16);

//   const payload = Date.now().toString();

//   const cipherParams = CryptoJS.AES.encrypt(payload, hash, {
//     mode: CryptoJS.mode.CTR,
//     padding: CryptoJS.pad.NoPadding,
//     iv: iv,
//   });

//   const tokenPreSharedKey = Buffer.concat([
//     Buffer.from(CryptoJS.enc.Hex.stringify(iv), "hex"),
//     Buffer.from(CryptoJS.enc.Hex.stringify(cipherParams.ciphertext), "hex"),
//   ]).toString("base64");

//   const instanceAxios = axios.create();
//   instanceAxios.interceptors.request.use(
//     (req) => {
//       return req;
//     },
//     (err) => {
//       return Promise.reject(err);
//     }
//   );

//   return instanceAxios.post(
//     KokattoIdentityBaseUrl + "/csppushnotif/pushnotif/offline/internal",
//     { clientId: clientId, email: email },
//     { headers: { Authorization: tokenPreSharedKey.replace(/['"]+/g, "") } }
//   );
// };

const expireSession = async () => {
  try {
    // await handleRequestLogout();
    await store.dispatch("submitLog", {
      payload: { info: "Session expired" },
      type: "AUTH_LOG",
      isError: false,
    });
    await store.dispatch("logoutAccount");
    // await store.dispatch("clearWSState");
    // await revokeAccountActivity();
    // await router.push("/login");
  } catch (error) {
    console.log(
      "file: newInterceptors.js ~ line 108 ~ expireSession ~ error",
      error
    );
  }
};

const revokeAccountActivity = async () => {
  const req: KoalaPlusAccountActivityRequest = {
    clientId: store.getters.getClientId,
    email: store.getters.getAccountEmail,
  };

  return RefreshableRequestHelper.requestKoala<KoalaAccountServiceClient>(
    () => {
      const serviceClient = new KoalaPlusAccountActivityServiceClient();
      try {
        return serviceClient.deleteAccountActivity(req);
      } catch (err) {
        console.log("Failed revoke account activity");
        return err as any;
      }
    }
  );
};

const attachTokenToRequest = (request: any, _token: any) => {
  console.log("file: interceptor ~ line 21 ~ attachTokenToRequest", _token);
  // const { koalaToken, kokattoToken } = _token.data;

  const token = getToken(request.url, _token.data);
  if (token) {
    request.headers["authorization"] = token;
  }

  // if (
  //   request.url.includes("portal.kokatto.com") ||
  //   request.url.includes("bvu48r7dyi") ||
  //   request.url.includes(process.env.VUE_APP_BASEURL_CRM) ||
  //   request.url.includes("j3emgg06kb")
  // ) {
  //   request.headers["authorization"] =
  //     "Bearer " + (kokattoToken.token || store.getters.getKokattoTokenAccess);
  // } else {
  //   request.headers["authorization"] =
  //     "Bearer " + (koalaToken.accessToken || store.getters.getKoalaTokenAccess);
  // }

  // If there is an edge case where access token is also set in request query,
  // this is also a nice place to add it
  // Example: /orders?token=xyz-old-token
  // if (/\/orders/.test(request.url)) {
  //   request.params.token = token;
  // }
};

const getLocation = (url: string) => {
  const match = url.match(
    /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  );
  return (
    match && {
      href: url,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  );
};

const getToken = (url: string, _token?: any) => {
  let token = null;
  const koalaToken = _token?.koalaToken || store.getters.getKoalaTokenAccess;
  const kokattoToken =
    _token?.kokattoToken || store.getters.getKokattoTokenAccess;
  const procatToken = store.getters.getProductCatalogTokenAccess;

  const isExcludedDomain = excludedAuthUrl.find((baseUrl) => url == baseUrl);
  const isKokattoDomain = kokattoDomain.find((baseUrl) =>
    url?.startsWith(baseUrl)
  );
  const isProcatDomain = procatDomain.find((baseUrl) =>
    url?.startsWith(baseUrl)
  );
  const isKoalaDomain = koalaDomain.find((baseUrl) => url?.startsWith(baseUrl));

  if (!isExcludedDomain) {
    if (isKokattoDomain) {
      token = `Bearer ${kokattoToken}`;
    } else if (isProcatDomain) {
      token = `Bearer ${procatToken}`;
    } else if (isKoalaDomain) {
      token = `Bearer ${koalaToken}`;
    }
  }

  return token;
};

const getHeaderTokens = (url: string, _token?: any) => {
  let token = null;
  const koalaToken = _token?.koalaToken || store.getters.getKoalaTokenAccess;
  const kokattoToken =
    _token?.kokattoToken || store.getters.getKokattoTokenAccess;
  const procatToken = store.getters.getProductCatalogTokenAccess;

  const isExcludedDomain = excludedAuthUrl.find((baseUrl) => url == baseUrl);
  const isProcatDomain = procatDomain.find((baseUrl) =>
    url?.startsWith(baseUrl)
  );
  const isKoalaDomain = koalaDomain.find((baseUrl) => url?.startsWith(baseUrl));

  if (!isExcludedDomain) {
    if (isProcatDomain) {
      token = {
        "x-kokatto-token": kokattoToken,
        "x-koala-token": koalaToken,
      } as any;
    } else if (isKoalaDomain) {
      token = {
        "x-kokatto-token": kokattoToken,
        "x-procat-token": procatToken,
      } as any;
    }
  }

  return token;
};

export default (axiosClient: any, customOptions = {}) => {
  console.log("file: interceptor ~ line 21 ~ default");
  let isRefreshing = false;
  const isRefreshed = false;
  let failedQueue: Array<any> = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    expireSession,
    ...customOptions,
  };
  const processQueue = (error: any, token: any = null) => {
    // console.log(
    //   "file: newInterceptors.js - newInterceptors processQueue ~ error, token",
    //   error,
    //   token
    // );
    // console.log("file: newInterceptors.js - failedQueue", failedQueue);
    const rejects = [];
    failedQueue.forEach((prom) => {
      if (error) {
        rejects.push(error);
        prom.reject(error);
      } else {
        // console.log(
        //   "file: newInterceptors.js - processQueue ~ prom resolve",
        //   prom
        // );
        prom.resolve(token);
      }
    });
    // console.log(
    //   "file: newInterceptors.js ~ line 179 ~ processQueue ~ rejects.length",
    //   rejects.length
    // );

    failedQueue = [];
  };

  const errorInterceptor = (error: any) => {
    // console.log("file: newInterceptors.js ~ line 236 ~ errorInterceptor");
    // console.log(error);

    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    // const status = (error.response && error.response.status) || 0;

    // if (isRefreshed) {
    //   //originalRequest.headers["Authorization"] = "Bearer " + newData.token;
    //   return axios(originalRequest);
    // }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err); // Ignore refresh token request's "err" and return actual "error" for the original request
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    //console.log("file: newInterceptors.js ~ line 263 ~ isRefreshing");
    return new Promise((resolve, reject) => {
      //console.log("file: newInterceptors.js ~ line 265 ~ Promise");
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then(async (tokenData: any) => {
          //console.log("tokenData ::", tokenData);
          await options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData);
          //isRefreshed = true;
          processQueue(null, tokenData);
          resolve(axiosClient.request(originalRequest));
        })
        .catch(async (err) => {
          //console.log("file: newInterceptors.js ~ line 296 ~ error", error);
          processQueue(err, null);
          await options.expireSession();
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  const requestInterceptor = (config: AxiosRequestConfig) => {
    const token = getToken(config.url ?? "");
    const headers = getHeaderTokens(config.url ?? "");
    if (token) {
      config.headers = {
        ...config.headers,
        authorization: token,
      };
    }

    if (headers) {
      config.headers = {
        ...config.headers,
        ...headers,
      };
    }

    /**
     * Call backend API for portal kokatto based on region
     * change hostname endpoint
     */
    const koalaToken = store.getters.getKoalaTokenAccess;
    const userData = koalaToken ? getKoalaUserDetail(koalaToken) : null;

    if (userData?.region === "JKT") {
      const sgCISUrl = process.env.VUE_APP_BASEURL_CIS_AWS || "";
      if (config.url?.startsWith(sgCISUrl)) {
        const jktCISUrl = process.env.VUE_APP_BASEURL_CIS_AWS_JKT || "";
        config.url = config.url?.replace(sgCISUrl, jktCISUrl);
      }

      const sgPortalUrl = process.env.VUE_APP_BASEURL_KOKATTO_PORTAL || "";
      if (config.url?.startsWith(sgPortalUrl)) {
        const jktPortalUrl =
          process.env.VUE_APP_BASEURL_KOKATTO_PORTAL_JKT || "";
        config.url = config.url?.replace(sgPortalUrl, jktPortalUrl);
      }

      const sgNotifTracker =
        process.env.VUE_APP_BASEURL_KOKATTO_PUSHNOTIF_TRACKER || "";
      if (config.url?.startsWith(sgNotifTracker)) {
        const jktNotifTracker =
          process.env.VUE_APP_BASEURL_KOKATTO_PUSHNOTIF_TRACKER_JKT || "";
        config.url = config.url?.replace(sgNotifTracker, jktNotifTracker);
      }

      console.log("new Url");
      console.log(config.url);
    }

    return config;
  };

  axiosClient.interceptors.response.use(undefined, errorInterceptor);

  // Add a request interceptor
  axiosClient.interceptors.request.use(requestInterceptor, (error: any) =>
    Promise.reject(error)
  );
};
