import { brandConstants } from "@/brand/current/PartnerConstants";
import { ActionTree, GetterTree, MutationTree } from "vuex";

type State = {
  balanceCredit: number;
  balanceBonusCredit: number;
  accountStoreName: string;
  accountName: string;
  subscriptionPackage: string;
  accountSubscriptionDateEnd: number;
  accountStatus: string;
  accountRole: string;
  accountId: string;
  koalaAccountId: string;
  koalaPlusAccountId: string;
  accountEmail: string;
  wabaId: string;
  wabaStatus: string;
  wabaPhone: string;
  username: string;
  sellerMode: string;
  isHideOnlinePayment: boolean;
  freeTrialDateStart: number;
  country: string;
  billedType: string;
  kokattoEmail: string;
  logTypes: Array<string>;
  fcmToken: string;
};

const state: State = {
  balanceCredit: 0,
  balanceBonusCredit: 0,
  accountStoreName: "",
  accountName: "",
  subscriptionPackage: "",
  accountSubscriptionDateEnd: 0,
  accountStatus: "",
  accountRole: "",
  accountId: "",
  koalaAccountId: "",
  koalaPlusAccountId: "",
  accountEmail: "",
  wabaId: "",
  wabaStatus: "",
  wabaPhone: "",
  username: "",
  sellerMode: "",
  isHideOnlinePayment: false,
  freeTrialDateStart: 0,
  country: "",
  billedType: "",
  kokattoEmail: "",
  logTypes: [],
  fcmToken: "",
};

const getters: GetterTree<State, any> = {
  getAccountStatus: (state) => state.accountStatus,
  getAccountName: (state) => state.accountName,
  getAccountStoreName: (state) => state.accountStoreName,
  getSubscriptionPackage: (state) => state.subscriptionPackage,
  getAccountSubscriptionDateEnd: (state) => state.accountSubscriptionDateEnd,
  getAccountRole: (state) => state.accountRole,
  getBalanceCredit: (state) => state.balanceCredit,
  getBalanceBonusCredit: (state) => state.balanceBonusCredit,
  getIsPremium: (state) =>
    // !brandConstants.PARTNER_PACKAGABLE || // if partner is not packageable, always set premium
    state.subscriptionPackage === "PRO" ||
    state.subscriptionPackage === "STARTER" ||
    state.subscriptionPackage === "ENTERPRISE",
  // state.accountStatus === "Premium" || state.accountStatus === "Free Trial",
  getIsFreeTrial: (state) => state.accountStatus === "Free Trial",
  // state.accountStatus === "Free Trial",
  getAccountId: (state) => state.accountId,
  getKoalaAccountId: (state) => state.koalaAccountId,
  getKoalaPlusAccountId: (state) => state.koalaPlusAccountId,
  getAccountEmail: (state) => state.accountEmail,
  getWabaId: (state) => state.wabaId,
  getWabaStatus: (state) => state.wabaStatus,
  getWabaPhone: (state) => state.wabaPhone,
  getUsername: (state) => state.username,
  getSellerMode: (state) => state.sellerMode,
  getIsHideOnlinePayment: (state) => state.isHideOnlinePayment,
  getFreeTrialDateStart: (state) => state.freeTrialDateStart,
  getAccountCountry: (state) => state.country,
  getBilledType: (state) => state.billedType,
  getKokattoEmail: (state) => state.kokattoEmail,
  // returns boolean whether the supplied logType should be logged
  shouldLog: (state) => (logType: string) =>
    state.logTypes.some((type) => logType === type),
  isChatCreditInsufficient: (state) => (cost: number) => {
    if (state.billedType === "POST_PAID") {
      return false;
    }

    return state.balanceCredit < cost;
  },
  getFcmToken: (state) => state.fcmToken,
};

const mutations: MutationTree<State> = {
  updateAccountStatus(state, value: string) {
    state.accountStatus = value ?? "Free";
  },
  updateAccountName(state, value: string) {
    state.accountName = value;
  },
  updateSubscriptionPackage(state, value: string) {
    state.subscriptionPackage = value;
  },
  updateAccountStoreName(state, value: string) {
    state.accountStoreName = value;
  },
  updateAccountSubscriptionDateEnd(state, value: number) {
    state.accountSubscriptionDateEnd = value;
  },
  updateAccountRole(state, value: string) {
    state.accountRole = value;
  },
  updateBalanceCredit(state, balanceCredit: number) {
    state.balanceCredit = balanceCredit;
  },
  updateBalanceBonusCredit(state, balanceBonusCredit: number) {
    state.balanceBonusCredit = balanceBonusCredit ? balanceBonusCredit : 0;
  },
  updateAccountId(state, value: string) {
    state.accountId = value;
  },
  updateKoalaAccountId(state, value: string) {
    state.koalaAccountId = value;
  },
  updateKoalaPlusAccountId(state, value: string) {
    state.koalaPlusAccountId = value;
  },
  updateAccountEmail(state, value: string) {
    state.accountEmail = value;
  },
  updateWabaStatus(state, value: string) {
    state.wabaStatus = value;
  },
  updateWabaId(state, value: string) {
    state.wabaId = value;
  },
  updateWabaPhone(state, value: string) {
    state.wabaPhone = value;
  },
  updateUsername(state, value: string) {
    state.username = value;
  },
  updateSellerMode(state, value: string) {
    state.sellerMode = value;
  },
  updateIsHideOnlinePayment(state, value: boolean) {
    state.isHideOnlinePayment = value;
  },
  updateFreeTrialDateStart(state, value: number) {
    state.freeTrialDateStart = value;
  },
  updateAccountCountry(state, value: string) {
    state.country = value;
  },
  updateBilledType(state, value: string) {
    state.billedType = value;
  },
  updateKokattoEmail(state, value: string) {
    state.kokattoEmail = value;
  },
  updateLogTypes(state, value: string) {
    try {
      state.logTypes = JSON.parse(value);
    } catch (error) {
      state.logTypes = [];
    }
  },
  updateFcmToken(state, value: string) {
    state.fcmToken = value;
  },
};

const actions: ActionTree<State, any> = {
  setAccountStatus(context, value: string) {
    context.commit("updateAccountStatus", value);
  },
  setAccountName(context, value: string) {
    context.commit("updateAccountName", value);
  },
  setSubscriptionPackage(context, value: string) {
    context.commit("updateSubscriptionPackage", value);
  },
  setAccountStoreName(context, value: string) {
    context.commit("updateAccountStoreName", value);
  },
  setAccountSubscriptionDateEnd(context, value: number) {
    context.commit("updateAccountSubscriptionDateEnd", value);
  },
  // setAccountRole(context, value: string) {
  //   context.commit("updateAccountRole", value);
  // },
  setBalance(context, balanceCredit: number) {
    context.commit("updateBalanceCredit", balanceCredit);
  },
  setBalanceBonus(context, balanceBonusCredit: number) {
    context.commit("updateBalanceBonusCredit", balanceBonusCredit);
  },
  setAccountId(context, value: string) {
    context.commit("updateAccountId", value);
  },
  setKoalaAccountId(context, value: string) {
    context.commit("updateKoalaAccountId", value);
  },
  setKoalaPlusAccountId(context, value: string) {
    context.commit("updateKoalaPlusAccountId", value);
  },
  setCountry(context, value: string) {
    context.commit("updateAccountCountry", value);
  },
  clearAccount(context) {
    context.commit("updateAccountStatus", "");
    context.commit("updateAccountName", "");
    context.commit("updateAccountStoreName", "");
    context.commit("updateAccountSubscriptionDateEnd", "");
    context.commit("updateAccountRole", "");
    context.commit("updateAccountId", "");
    context.commit("updateKoalaAccountId", "");
    context.commit("updateKoalaPlusAccountId", "");
    context.commit("updateAccountEmail", "");
    context.commit("updateWabaStatus", "");
    context.commit("updateWabaId", "");
    context.commit("updateWabaPhone", "");
    context.commit("updateBalanceCredit", 0);
    context.commit("updateBalanceBonusCredit", 0);
    context.commit("updateSubscriptionPackage", "");
    context.commit("updateUsername", "");
    context.commit("updateFreeTrialDateStart", "");
    context.commit("updateAccountCountry", "");
    context.commit("updateBilledType", "");
    context.commit("updateKokattoEmail", "");
    context.commit("updateLogTypes", "[]");
  },
  setAccountEmail(context, value: string) {
    context.commit("updateAccountEmail", value);
  },
  setWabaStatus(context, value: string) {
    context.commit("updateWabaStatus", value);
  },
  setWabaId(context, value: string) {
    context.commit("updateWabaId", value);
  },
  setWabaPhone(context, value: string) {
    context.commit("updateWabaPhone", value);
  },
  setUsername(context, value: string) {
    context.commit("updateUsername", value);
  },
  setSellerMode(context, value: string) {
    context.commit("updateSellerMode", value);
  },
  setIsHideOnlinePayment(context, value: boolean) {
    context.commit("updateIsHideOnlinePayment", value);
  },
  setFreeTrialDateStart(context, value: number) {
    context.commit("updateFreeTrialDateStart", value);
  },
  setAccountCountry(context, value: string) {
    context.commit("updateAccountCountry", value);
  },
  setBilliedType(context, value: string) {
    context.commit("updateBilledType", value);
  },
  setKokattoEmail(context, value: string) {
    context.commit("updateKokattoEmail", value);
  },
  setKokattoClientLogTypes(context, value: string) {
    context.commit("updateLogTypes", value);
  },
  setFcmToken(context, value: string) {
    console.log("file: Account.ts:275 ~ setFcmToken ~ value:", value);
    context.commit("updateFcmToken", value);
  },
};

const Account = { state, getters, mutations, actions };

export default Account;
