interface BulkTicketUpdateTranslation {
  dialogTitle: string;
  formLabelChatSettings: string;
  formPlaceholderSetStatus: string;
  formPlaceholderChatPriority: string;
  formPlaceholderAddTag: string;
  formPlaceholderRemoveTag: string;
  formLabelAssignCSAgent: string;
  formLabelRadioAgent: string;
  formLabelRadioGroup: string;
  formPlaceholderSelectCSAgent: string;
  formPlaceholderSelectCSGroupAgent: string;
  formLabelFormType: string;
  formPlaceholderSelectFormType: string;
  formLabelSubject: string;
  formPlaceholderSubject: string;
  formLabelRadioSendAsPublicReply: string;
  formLabelRadioSendAsInternalNote: string;
  formPlaceholderPublicReplyTypingArea: string;
  formPlaceholderInternalNoteTypingArea: string;
  formCTAUpdate: string;
  formCTACancel: string;
  formUpdateSuccess: string;
  formUpdateFailed: string;
  formDownloadLog: string;
  generalError: string;
  formLabelRemarks: string;
  formPlaceholderRemarks: string;
  formPlaceholderRemarksTemplate: string;
}

interface Translation {
  id: BulkTicketUpdateTranslation;
  en: BulkTicketUpdateTranslation;
}

const id: BulkTicketUpdateTranslation = {
  dialogTitle: "{0} chat akan kamu update",
  formLabelChatSettings: "Pengaturan chat",
  formPlaceholderSetStatus: "Set status",
  formPlaceholderChatPriority: "Set prioritas chat",
  formPlaceholderAddTag: "Tambah tag",
  formPlaceholderRemoveTag: "Hapus tag",
  formLabelAssignCSAgent: "Tugaskan CS",
  formLabelRadioAgent: "Agen",
  formLabelRadioGroup: "Grup",
  formPlaceholderSelectCSAgent: "Pilih agen",
  formPlaceholderSelectCSGroupAgent: "Pilih grup agen",
  formLabelFormType: "Jenis form",
  formPlaceholderSelectFormType: "Set jenis form",
  formLabelSubject: "Konten",
  formPlaceholderSubject: "Set subjek",
  formLabelRadioSendAsPublicReply: "Kirim sebagai pesan",
  formLabelRadioSendAsInternalNote: "Kirim sebagai internal note",
  formPlaceholderPublicReplyTypingArea: "Ketik isi pesan disini",
  formPlaceholderInternalNoteTypingArea: "Ketik isi internal note disini",
  formCTAUpdate: "Update chat",
  formCTACancel: "Batalkan",
  formUpdateSuccess: "Update {0} chat berhasil.",
  formUpdateFailed:
    "Gagal update {0} dari total {1} chat. Download log untuk lihat detail.",
  formDownloadLog: "Download log",
  generalError:
    "Gagal melakukan perubahan pada tiket. Silakan coba lagi nanti.",
  formLabelRemarks: "Isi remarks",
  formPlaceholderRemarks: "Isi keterangan",
  formPlaceholderRemarksTemplate: "Pilih keterangan",
};

const en: BulkTicketUpdateTranslation = {
  dialogTitle: "{0} chats will be updated",
  formLabelChatSettings: "Chat settings",
  formPlaceholderSetStatus: "Set status",
  formPlaceholderChatPriority: "Set chat priority",
  formPlaceholderAddTag: "Add tag",
  formPlaceholderRemoveTag: "Remove tag",
  formLabelAssignCSAgent: "Assign CS agent",
  formLabelRadioAgent: "Agent",
  formLabelRadioGroup: "Group",
  formPlaceholderSelectCSAgent: "Select CS agent",
  formPlaceholderSelectCSGroupAgent: "Select CS agent group",
  formLabelFormType: "Form type",
  formPlaceholderSelectFormType: "Set form type",
  formLabelSubject: "Content",
  formPlaceholderSubject: "Set subject",
  formLabelRadioSendAsPublicReply: "Send as message",
  formLabelRadioSendAsInternalNote: "Send as internal note",
  formPlaceholderPublicReplyTypingArea: "Type your message here",
  formPlaceholderInternalNoteTypingArea: "Type the internal note here",
  formCTAUpdate: "Update chat",
  formCTACancel: "Cancel",
  formUpdateSuccess: "Updated {0} chats successfully.",
  formUpdateFailed:
    "Failed to update {0} of total {1} chats. Download log to view details.",
  formDownloadLog: "Download log",
  generalError: "Failed to update tickets. Please try again later.",
  formLabelRemarks: "Remarks",
  formPlaceholderRemarks: "Enter remarks",
  formPlaceholderRemarksTemplate: "Select template",
};

const translations: Translation = { id, en };
export default translations;
