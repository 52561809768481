import { ActionTree, GetterTree, MutationTree } from "vuex";
import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";
import { KokattoConfigServiceClient } from "@/serviceClients/KokattoConfigServiceClient";
import { KokattoGetCSPlatformConfigResponse } from "@/serviceClients/responses/KokattoGetCSPlatformConfigResponse";
import {
  KokattoErrorResponse,
  isKokattoErrorResponse,
} from "@/serviceClients/responses/KokattoErrorResponse";
import { CSPConfiguration } from "@/models/CSPConfiguration";

interface State {
  configuration: CSPConfiguration;
}

const DEFAULT_STATE: State = {
  configuration: {
    featureCs: 0,
    isAutoAssignFeature: false,
    isDisplayChatroomEmoji: false,
    isDisplayChatroomAttachment: false,
  },
};

const state: State = { ...DEFAULT_STATE };

const getters: GetterTree<State, any> = {
  getConfiguration: (state) => state.configuration,
};

const mutations: MutationTree<State> = {
  setConfiguration(state, payload: CSPConfiguration) {
    state.configuration = payload;
  },
};

const actions: ActionTree<State, any> = {
  async getCSPConfiguration({ commit }) {
    try {
      const response:
        | KokattoGetCSPlatformConfigResponse
        | KokattoErrorResponse = await RefreshableRequestHelper.requestKokatto<KokattoGetCSPlatformConfigResponse>(
        () => {
          const kokattoConfigServiceClient = new KokattoConfigServiceClient();
          return kokattoConfigServiceClient.getConfig();
        }
      );

      if (!isKokattoErrorResponse(response)) {
        commit("setConfiguration", response.configuration);
      }
    } catch (error) {
      console.error("[ERROR] getCSPConfiguration() - CSPConfiguration", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
