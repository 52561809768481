const id = {
  menuVoice: "Voice Call",
  menuVoiceBroadcast: "Broadcast",
  transcriptionDialogTitle: "Detil Telepon",
  templateVoice: "Voice Broadcast",
  templateVoiceDescription: "Voice Broadcast Desc",
  tabTitleVoiceBroadcast: "Broadcast",
  tabTitleVoiceHistory: "History",
  recordingTitle: "Rekaman",
  transcriptionTitle: "Transkripsi",
  transcriptionUserName: "Pengguna",
  transcriptionAssistantName: "Asisten",
  transcriptionContentEmpty: "Belum ada data",
  notificationStatusSent: "Terkirim",
  notificationStatusRead: "Dibaca",
  notificationStatusConnected: "Terhubung",
  notificationStatusFailed: "Gagal",
  notificationStatusSending: "Terkirim",
};

const en = {
  menuVoice: "Voice Call",
  menuVoiceBroadcast: "Broadcast",
  transcriptionDialogTitle: "Call Details",
  templateVoice: "Voice Broadcast",
  templateVoiceDescription: "Voice Broadcast Desc",
  tabTitleVoiceBroadcast: "Broadcast",
  tabTitleVoiceHistory: "History",
  recordingTitle: "Recording",
  transcriptionTitle: "Transcription",
  transcriptionUserName: "User",
  transcriptionAssistantName: "Assistant",
  transcriptionContentEmpty: "No available data",
  notificationStatusSent: "Sent",
  notificationStatusRead: "Read",
  notificationStatusConnected: "Connected",
  notificationStatusFailed: "Failed",
  notificationStatusSending: "Sent",
};
export default { id, en };
