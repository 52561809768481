import { CsAgent, CsAgentGroup } from "@/models/CsAgentManagementModel";
import { BulkTicketUpdateFormState, BulkUpdateState, TagState } from "./types";
import { AppliedMacroState } from "@/store/modules/Macros/types";

export enum BulkTicketUpdateStatus {
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export const DEFAULT_TAG_STATE: TagState = {
  loading: false,
  data: [],
  error: null,
};

export const DEFAULT_BULK_UPDATE_STATE: BulkUpdateState = {
  loading: false,
  error: null,
  jobId: "",
  totalTickets: 0,
};

export enum MessageType {
  Public = "PublicMessage",
  Internal = "InternalMessage",
}

export enum TicketPriority {
  High = 1,
  Medium = 2,
  Normal = 3,
}

export const DEFAULT_SNACKBAR_STATE = {
  isActive: false,
  message: "",
  color: "",
  timeout: -1,
};

export const DEFAULT_BULK_TICKET_UPDATE_FORM_STATE: BulkTicketUpdateFormState =
  {
    status: "",
    priority: "",
    addTags: [],
    removeTags: [],
    sendMessageAs: MessageType.Public, // PublicMessage or InternalMessage
    isInternalChat: false,
    message: "",
    isSelectingCS: false,
    assignedCs: null as CsAgent | null,
    assignedCsGroup: null as CsAgentGroup | null,
    remarks: "",
    appliedMacro: null as AppliedMacroState | null,
    customForm: {
      formRequest: {
        ticketFormId: "",
        ticketFormName: "",
        ticketFormFields: [],
      },
      mandatory: {
        ticketForm: {},
      },
      optional: {},
      isValid: false,
    },
  };
